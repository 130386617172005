<template>
  <el-card class='account-remittance-not-account business-handling my-card' :body-style="cardBody">
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('transferBusiness')}} / {{$t('transferAccount')}}</span></template>
    </el-page-header>
    <div class="form-container">
      <el-divider content-position="left">
        <el-form inline ref="queryForm" :model="queryForm" :rules="rules" style="height:45px;overflow:hidden;">
          <el-form-item :label="$t('transferorInfo')" prop="param" style="margin-bottom:0">
            <el-input size="mini" v-model="queryForm.param" :placeholder="$t('inputPhoneOrAccount')"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:0">
            <el-button size="mini" type="primary" @click="onQuery('queryForm','transferPeopleInfo')">{{$t('query')}}{{$t('transferorInfo')}}</el-button>
          </el-form-item>
        </el-form>
      </el-divider>
      <el-form v-if="transferPeopleInfo" ref="transferPeopleInfo" :model="transferPeopleInfo" label-position="top">
        <el-input type="hidden" size="medium" v-model="transferPeopleInfo.businessCode" disabled></el-input>
        <el-row :gutter="20">
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('firstName')">
              <el-input size="medium" v-model="transferPeopleInfo.firstName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('lastName')">
              <el-input size="medium" v-model="transferPeopleInfo.lastName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('phone')">
              <el-input size="medium" v-model="transferPeopleInfo.phone" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('idNumber')">
              <el-input size="medium" v-model="transferPeopleInfo.idCard" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('accountAmount')">
              <el-input size="medium" v-model="transferPeopleInfo.accountAmount" disabled>
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('account')">
              <el-input size="medium" v-model="transferPeopleInfo.cardNo" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 收款人信息 -->
      <el-divider content-position="left" class="update-el-divider">
        <el-form inline ref="queryPayeeInfo" :model="queryPayeeInfo" :rules="rules" style="height:45px;overflow:hidden;">
          <el-form-item :label="$t('beneficiaryInfo')" prop="param" style="margin-bottom:0">
            <el-input size="mini" v-model="queryPayeeInfo.param" :placeholder="$t('inputPhoneOrAccount')"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:0">
            <el-button size="mini" type="primary" @click="onQuery('queryPayeeInfo','payeeInfo')">{{$t('query')}}{{$t('beneficiaryInfo')}}</el-button>
          </el-form-item>
        </el-form>
      </el-divider>
      <el-form v-if="payeeInfo" ref="payeeInfo" :model="payeeInfo" label-position="top">
        <el-row :gutter="20">
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('firstName')">
              <el-input size="medium" v-model="payeeInfo.firstName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('lastName')">
              <el-input size="medium" v-model="payeeInfo.lastName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('phone')">
              <el-input size="medium" v-model="payeeInfo.phone" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('idNumber')">
              <el-input size="medium" v-model="payeeInfo.idCard" disabled></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('accountAmount')">
              <el-input size="medium" v-model="payeeInfo.accountAmount" disabled>
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('account')">
              <el-input size="medium" v-model="payeeInfo.cardNo" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 转账信息 -->
      <el-divider content-position="left" class="update-el-divider">{{$t('transferInfo')}}</el-divider>
      <el-form v-if="payeeInfo&&transferPeopleInfo" ref="transferInfo" :model="transferInfo" :rules="rules" label-position="top">
        <el-row :gutter="20">
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('transfer amount')" prop="amount">
              <el-input size="medium" v-model="transferInfo.amount" @blur="calculateServiceCharge" :disabled="isCompute">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('serviceCharge')+' '+(isCompute?$t('calculating')+'……':'')" prop="serviceChargeAmount">
              <el-input size="medium" v-model="transferInfo.serviceChargeAmount" disabled :placeholder="$t('AfterEnteringTheAmountItWillBAutomaticallyCalculated')">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('Service fee discount')" prop="coupon">
              <el-input size="medium" v-model="transferInfo.coupon" type="number">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <transaction-tax-display businessCode="30" :transaction-amount="transferInfo.amount"></transaction-tax-display>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('serviceCharge')+$t('payment')" prop="payServiceChargeType">
              <el-select size="medium" v-model="transferInfo.payServiceChargeType">
                <el-option :label="$t('remitterPayment')" :value="0"></el-option>
                <el-option :label="$t('payeePayment')" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('reason2')" prop="purpose">
              <el-select size="medium" v-model="transferInfo.purpose">
                <el-option :label="$t('School aid')" :value="$t('School aid')"></el-option>
                <el-option :label="$t('Family assistance')" :value="$t('Family assistance')"></el-option>
                <el-option :label="$t('Building construction')" :value="$t('Building construction')"></el-option>
                <el-option :label="$t('Business settlement')" :value="$t('Business settlement')"></el-option>
                <el-option :label="$t('Service settlement')" :value="$t('Service settlement')"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="10" :lg="8" :xl="6">
            <el-form-item :label="$t('messageAuthenticationCode')" prop="smsCode">
              <el-input size="medium" v-model="transferInfo.smsCode">
                <el-button slot="append" @click="getSmsVerification" :disabled="surplusTime>0">
                  <span v-if="!surplusTime">{{$t('Send SMS verification code')}}</span>
                  <span v-else>{{$t("send it again")+`(${surplusTime})`}}</span>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('remark')">
              <el-input size="medium" type="textarea" v-model="transferInfo.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bottom-button" v-if="transferPeopleInfo&&payeeInfo&&transferPeopleInfo">
      <el-button v-if="!printParam" :loading="isLoading" size="medium" type="primary" @click="onSubmit('transferInfo')">{{$t('submit')}}</el-button>
      <el-button v-else size="medium" @click="printVisible=true">{{$t('printrRceipt')}}</el-button>
    </div>

    <el-dialog :title="$t('printrRceipt')" :visible.sync="printVisible" width="500px" :modal-append-to-body="false" :destroy-on-close="true" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <print-credentials :param="printParam" :isClosePrint.sync="printVisible" />
    </el-dialog>
  </el-card>
</template>

<script>
import printCredentials from '@/components/printCredentials'
import customer from "@/api/customer"
import transactionInfo from "@/api/transactionInfo";
import TransactionTaxDisplay from '@/components/TransactionTaxDisplay'
import business from '@/api/business'
export default {
  components: { printCredentials, TransactionTaxDisplay },
  data () {
    return {
      isLoading: false,

      /**查询汇款人信息 */
      queryForm: {},
      /**查询收款人账户 */
      queryPayeeInfo: {},

      /**转账人信息 */
      transferPeopleInfo: null,
      /**收款人信息 */
      payeeInfo: null,
      /**汇款信息 */
      transferInfo: { coupon: 0 },

      isCompute: false,

      printVisible: false,
      printParam: null,

      surplusTime: 0
    }
  },
  computed: {
    cascader () { return this.$store.state.format.cascader },
    cardBody () { return this.$store.state.style.cardBody },
    countryTree () { return this.$store.state.globalParameter.countryTree; },
    rules () {
      return {
        param: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceChargeAmount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payServiceChargeType: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        country_region_city: [{ type: "array", required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        smsCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        purpose: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  watch: {
    /**手续费优惠输入金额后计算优惠后的手续费 */
    "transferInfo.coupon" (newValue) {
      /**优惠手续费金额不能是负数 */
      if (newValue < 0) {
        this.transferInfo.coupon = 0;
        return;
      }
      /**优惠后手续费不能是负数 */
      if (this.transferInfo.purposeBefore - this.transferInfo.coupon < 0) {
        this.transferInfo.coupon = 0;
        return;
      }

      this.$set(this.transferInfo, 'serviceChargeAmount', this.transferInfo.purposeBefore - this.transferInfo.coupon);
    },
    "transferInfo.amount" (newValue) { if (newValue < 0) this.transferInfo.amount = 0 },
  },
  methods: {
    calculateServiceCharge () {
      let _this = this;
      if (!_this.transferInfo.amount) {
        _this.$set(_this.transferInfo, "serviceChargeAmount", undefined)
        return;
      }
      _this.isCompute = true;
      business.serviceChargeCompute({
        param: { amount: _this.transferInfo.amount, businessCode: _this.$route.params.code },
        success: res => {
          setTimeout(() => {
            if (res.code != 200) _this.$message.warning(_this.$t(res.msg));

            /**手续费 */
            _this.$set(_this.transferInfo, "serviceChargeAmount", res.data || 0);
            /**手续费优惠前 */
            _this.$set(_this.transferInfo, "purposeBefore", res.data || 0);

            _this.isCompute = false;
          }, 500);
        }
      })
    },
    /**查询转款人和收款人信息 */
    onQuery (refName, rank) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (valid) {
          let p = {};
          if (_this[refName].param.length === 15) p.cardNo = _this[refName].param;
          else p.phone = _this[refName].param;

          customer.getByAccountOrPhone({
            param: p,
            success: res => {
              if (res.code != 200) {
                _this.$message.warning(_this.$t(res.msg))
                return;
              }
              _this[rank] = {
                businessCode: _this.$route.params.code,
                firstName: res.data.name,
                lastName: res.data.surname,
                phone: res.data.phoneNo,
                idCard: res.data.idCard,
                accountAmount: res.data.accountAmount,
                cardNo: res.data.cardNo,
                cardId: res.data.cardId
              };
            }
          })
        }
        else return false;
      })
    },
    getSmsVerification () {
      let _this = this;
      transactionInfo.sendVerificationCode({
        param: { phone: this.transferPeopleInfo.phone }, success: function (res) {
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg))
            return
          }

          _this.surplusTime = 60;
          let interval = setInterval(() => {
            _this.surplusTime -= 1;
            if (_this.surplusTime === 0)
              clearInterval(interval);
          }, 1000)

          _this.$message.success(_this.$t("SMS verification code sent successfully") + "，" + _this.$t("Verification code Receiving number") + _this.transferPeopleInfo.phone);
        }
      })
    },
    onSubmit (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isLoading = true;
        let p = {
          businessCode: _this.transferPeopleInfo.businessCode,
          //转账人信息
          remittorCardId: _this.transferPeopleInfo.cardId,
          remittorCardNo: _this.transferPeopleInfo.cardNo,
          //收款人信息
          payeeCardId: _this.payeeInfo.cardId,
          payeeCardNo: _this.payeeInfo.cardNo,
          //转账信息
          ..._this[refName]
        }

        transactionInfo.transferAccounts({
          param: p,
          success: res => {
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'));
              _this.$set(_this, "printParam", res.data)
              _this.$set(_this, "printVisible", true)
            }
            else {
              _this.$message.warning(_this.$t(res.msg));
            }
            _this.isLoading = false;
          }
        })
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.account-remittance-not-account.business-handling {
  height: 100%;
  width: 100%;
  .el-card__body {
    position: relative;
    .form-container {
      position: absolute;
      top: 60px;
      bottom: 80px;
      left: 20px;
      right: 0;
      padding-right: 20px;
      overflow: auto;
    }
  }
  .el-form {
    height: auto;
  }
  .update-el-divider {
    margin-top: 40px;
  }
}
</style>